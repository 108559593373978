<template>
  <base-section id="news">
    <base-section-heading title="News" />

    <v-container>
      <v-row class="d-flex justify-center">
        <base-title
          class="primary--text"
          title="Mein Kurs für deinen Einstieg in die faszinierende Welt Astrologie & Psychologie"
          tag="div"
        />

        <base-body>
          Schon lange bin ich fasziniert von der Astrologie und davon, wie sie uns helfen kann, das Leben besser zu verstehen. Die Astrologie gibt uns tiefe Einblicke in unsere Persönlichkeit, unsere Stärken und Herausforderungen – und genau das möchte ich in meiner Kompaktausbildung weitergeben.
        </base-body>

        <base-title
          class="primary--text"
          title="Was dich in meiner online Kompakt-Ausbildung erwartet"
          tag="div"
        />

        <base-body>
          In meinem Kurs lernst du die Grundlagen der astrologisch-psychologischen Persönlichkeitsanalyse. Am Ende wirst du nicht nur in der Lage sein, eine astrologisch-psychologische Persönlichkeitsanalyse für dich
          und andere durchzuführen. Du kannst ebenfalls das Potenzial und den Charakter einer Person ganzheitlich erfassen und eine Standortbestimmung erstellen. Zudem gewinnst du vertiefte und neue Erkenntnisse über dich selbst und deine Mitmenschen.
        </base-body>

        <base-title
          class="primary--text"
          title="Für wen ist der Kurs gedacht?"
          tag="div"
        />

        <base-body>
          Dieser Kurs ist perfekt für alle, die mehr über die Zusammenhänge im Leben wissen wollen. Auch ein erstklassiges Zusatztool für Coaches, Supervisoren,
          Therapeuten. Die Werkzeuge, die ich dir in diesem Kurs an die Hand gebe, sind nicht nur eine Bereicherung für dein eigenes Leben, sondern auch ein wunderbares Hilfsmittel für deine berufliche Praxis.
        </base-body>

        <base-title
          class="primary--text"
          title="Was du mitnehmen wirst"
          tag="div"
        />

        <base-body>
          Neben dem fundierten astrologischen Wissen wirst du in meinem Kurs auch viele neue Erkenntnisse über dich selbst gewinnen. Die Astrologie hilft uns, die Zusammenhänge im Leben zu verstehen und uns weiterzuentwickeln. Du wirst lernen, Menschen und ihre Potenziale besser zu erfassen – ein Geschenk, das dein Leben und deine Sichtweise auf die Welt nachhaltig bereichern wird.
        </base-body>

        <base-title
          class="primary--text"
          title="-"
          tag="div"
        />

        <base-body>
          Wenn dich das anspricht, freue ich mich darauf, dich in meiner Kompaktausbildung willkommen zu heissen! Es sind noch Plätze frei, melde dich also gern an, wenn du dich angesprochen fühlst.

          Ich freue mich darauf, gemeinsam mit dir die Welt der Astrologie & Psychologie zu entdecken!
        </base-body>

        <base-title
          title="Herzlichst, Monica"
          tag="div"
        />
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',

    components: {
    },

    data: () => ({
      articles: [
        {
          icon: 'mdi-image',
          date: 'Jan 12, 2020',
          category: 'Design',
          comments: 5,
          title: 'Lorem ipsum dolor, sit amet consectetur',
          text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur, nulla voluptas sed[...]',
        },
        {
          icon: 'mdi-play',
          date: 'Oct 19, 2019',
          category: 'Strategy',
          comments: 8,
          title: 'Lorem ipsum dolor, sit amet consectetur',
          text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur, nulla voluptas sed[...]',
        },
        {
          icon: 'mdi-text',
          date: 'Jul 24, 2019',
          category: 'Business',
          comments: 13,
          title: 'Lorem ipsum dolor, sit amet consectetur',
          text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur, nulla voluptas sed[...]',
        },
      ],
    }),
  }
</script>
